@import '../include-media.scss';
$breakpoints: (small: 320px, medium: 867px, large: 1024px);

.container-page-terms {
  margin: 10% 20% 10% 20%;

  &-title {

    h1 {
      font-family: 'Helvetica';
      opacity: 0.87;
      color: rgba(0,0,0,0.87);
      font-size: 32px;
      letter-spacing: 0.5px;
      line-height: 40px;
    }

    p {
      font-family: 'Helvetica';
      color: rgba(0,0,0,0.8);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: justify;
    }

    h3 {
      font-family: 'Helvetica';
      color: rgba(0,0,0,0.8);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: justify;
    }

  }
}

@include media("<medium") {

  .container-page-terms {
    margin: 20px;
  }

}
