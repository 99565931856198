@import '../include-media.scss';
$breakpoints: (small: 320px, medium: 667px, large: 1024px);

.footer-container {
  display: flex;
  background-color: #F2F8FE;
  width: 100%;
  height: 56px;
  bottom: 0;
  position: absolute;

  &-icon {
    flex: 1;
    width: 200px;
    vertical-align: center;
    color: rgba(0,0,0,0.62);
    font-family: "Helvetica";
    font-size: 12px;
    letter-spacing: 0;
    text-align: left;
    margin: auto auto auto 6%;

    &-image {
      flex: 1;
      height: 30px;
      object-fit: contain;
      margin: auto 10px auto;
      vertical-align: middle;
    }
  }

  &-copy {
    flex: 1;
    margin: auto;
    margin-right: 6%;
    vertical-align: center;
    color: rgba(0,0,0,0.62);
    font-family: "Helvetica";
    font-size: 12px;
    letter-spacing: 0;
    text-align: right;

    a {
      color: #1772DC;
      text-decoration: none;
    }
  }
}

@include media("<medium") {

  .footer-container {
    &-icon {
      display: none;
    }
  }

}
