@import '../include-media.scss';
$breakpoints: (small: 330px, medium: 867px, large: 1024px);


.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1
}

.social-container {
  margin-top: 20px;
  margin-left: 40px;
  height: 87px;
  width: 345px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 2px;
  background-color: #FFFFFF;

  &-help {
    padding: 15px 0px 0px 15px;

    p {
      margin: auto;
      height: 18px;
      color: #54565B;
      font-family: 'Helvetica';
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 18px;
      margin-bottom: 5px;
    }
  }

  &-icons {
    margin: auto;

    img {
      padding-left: 5px;
      height: 40px;
      width: 40px;
      object-fit: fill;
    }

    .st-custom-button[data-network] {
      display: inline-block;
    }
  }
}

@include media("<medium") {

  .social-container {
    margin: 20px auto 20px auto;
  }

}

@include media("<small") {

  .social-container {
    width: 100%;
  }

}
