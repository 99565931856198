@import '../include-media.scss';
$breakpoints: (small: 420px, medium: 867px, large: 1024px);

.container-page-thank-you {
  margin: auto;
  width: 712px;
  max-height: 300px;
  background-color: white;
  padding: 20px;
  box-shadow: 10px 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);

  &-title {

    .img-container {
      height: 110px;
      object-fit: contain;
    }

    h1 {
      color: #FFFFFF;
      font-family: 'Helvetica';
      color: #FA6400;
      font-size: 32px;
      letter-spacing: 0.5px;
      margin: 10px;
    }

    h2 {
      height: 32px;
      color: #FF9934;
      font-family: 'Helvetica';
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }

    .divider {
      margin: 50px;
      color: white;
    }

    hr {
      box-sizing: border-box;
      height: 1px;
      width: 90%;
      border-top: 1px solid rgba(255,255,255,0.3);
    }

    .paragraph {
      margin-top: 20px;
      max-width: 528px;
      font-family: 'Helvetica';
      color: rgba(0,0,0,0.8);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      max-width: 400px;
    }

    h3 {
      font-family: 'Helvetica';
      color: rgba(0,0,0,0.8);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: justify;
    }

    button {
      margin-top: 10px;
      height: 56px;
      width: 200px;
      border-radius: 8px;
      background-color: #ed1a3d;
      color: #4C4C4C;
      font-family: "Helvetica";
      font-size: 16px;
      border: none;
    }

  }
}

.thank-you-social-container {
  margin: 20px auto;
  height: 97px;
  width: 345px;

  &-help {
    p {
      margin: 10px auto;
      color: rgba(255,255,255,0.7);
      font-family: 'Helvetica';
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 12px;
      text-align: center;
    }
  }

  &-icons {
    margin: auto;

    img {
      padding: 5px;
      height: 40px;
      width: 40px;
      object-fit: fill;
    }
  }
}

.receipt {
  width: 712px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  margin: 30px auto;
  padding: 20px;
  font-family: 'Helvetica';
  font-size: 14px;


  &-title {
    height: 16px;
    width: 196px;
    color: #002856;
    font-family: 'Helvetica';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: left;
  }

  &-text {
    width: 664px;
    opacity: 0.87;
    color: #002856;
    font-family: 'Helvetica';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 20px;
  }
}

.st-custom-button[data-network] {
  display: inline-block;
}

@include media("<medium") {

  .container-page-thank-you {
    height: 525px;
    width: 80%;

    &-title {

      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 24px;
      }
    }
  }

  .thank-you-social-container {
    width: 80%;
  }

  .receipt {
    width: 80%;
    font-family: 'Helvetica';

    &-text {
      width: 100%;
    }

    &-text {
      width: 100%;
    }
  }

}

@include media("<small") {

  .container-page-thank-you {
    height: 675px;
    width: 80%;

    .img-container {
      height: 100px;
      object-fit: contain;
    }

    &-title {

      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 24px;
      }
    }
  }

  .thank-you-social-container {
    width: 80%;
  }

  .receipt {
    width: 80%;

    &-text {
      width: 100%;
    }

    &-text {
      width: 100%;
    }
  }

}
