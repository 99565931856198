@import './include-media.scss';
$breakpoints: (
	small: 330px,
	medium: 867px,
	large: 1024px,
);

body {
	background-color: #fbfbfb;
	margin: 0;
	min-height: 100vh;
	padding-bottom: 56px;
	position: relative;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	max-width: 1200px;
	margin: auto;
}

.topTitle {
	h1 {
		font-family: 'Helvetica';
		opacity: 0.87;
		color: black;
		font-size: 32px;
		letter-spacing: 0.5px;
		line-height: 40px;
		text-align: center;
	}
}

.column {
	display: flex;
	flex-direction: column;
	flex-basis: 30%;
	flex: 1;
	margin-bottom: 15px;
}

.disclaimer {
	margin: 20px;
	font-family: 'Helvetica';
	opacity: 0.87;
	color: grey;
	font-size: 9px;
	text-align: right;
}

.double-column {
	display: flex;
	flex-direction: column;
	min-width: 400px;
	max-width: 70%;
	flex-basis: 70%;
	flex: 2;
}

.section-main {
	margin: 20px;

	&-title {
		img {
			width: 100%;
			max-height: 400px;
			object-fit: contain;
		}
	}

	&-description {
		margin-top: 32px;

		p {
			font-family: 'Helvetica';
			color: black;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 24px;
			text-align: justify;
		}

		li {
			font-family: 'Helvetica';
			color: black;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 24px;
			text-align: justify;
		}
	}

	&-callout {
		h1 {
			font-family: 'Helvetica';
			opacity: 0.87;
			color: #002856;
			font-size: 24px;
			letter-spacing: 0.5px;
		}

		p {
			font-family: 'Helvetica';
			color: #002856;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 24px;
			text-align: justify;
		}

		&-list {
			&-title {
				margin-bottom: 10px;
				font-family: 'Helvetica';
				color: #002856;
				font-size: 14px;
				letter-spacing: 0;
				line-height: 24px;
				text-align: justify;
			}

			color: #002856;
			font-family: 'Helvetica';
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 24px;
		}
	}
}

.section-donate {
	margin: 20px 20px 20px 20px;
	flex-direction: column;

	&-disclaimer {
		margin: 20px 40px auto;
		font-family: 'Helvetica';
		opacity: 0.87;
		color: grey;
		font-size: 9px;
		text-align: left;
		width: 345px;
	}
}

@include media('<large') {
	h1 {
		font-family: 'Helvetica';
		opacity: 0.87;
		color: #002856;
		font-size: 32px;
		letter-spacing: 0.5px;
		line-height: 40px;
		margin-left: 20px;
		margin-right: 20px;
	}

	.column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 1;
	}

	.double-column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		max-width: 100%;
		flex: 2;
	}

	.section-donate {
		margin: 0px 20px 0px 20px;
		flex-direction: column;
	}
}

@include media('<medium') {
	.topTitle {
		h1 {
			font-size: 24px;
		}
	}

	.column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 1;
	}

	.double-column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 2;
	}

	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.top {
		margin: 20px;
	}

	.section-main {
		margin: 20px;

		&-title {
			img {
				width: 100%;
				max-height: 300px;
			}
		}

		&-description {
			margin-top: 32px;

			p {
				font-family: 'Helvetica';
				color: black;
				font-size: 14px;
				letter-spacing: 0;
				line-height: 24px;
				text-align: justify;
			}
		}
	}

	.section-donate {
		margin: 0px 20px 0px 20px;
		flex-direction: column;

		&-disclaimer {
			margin: 20px auto;
			font-family: 'Helvetica';
			opacity: 0.87;
			color: grey;
			font-size: 9px;
			text-align: left;
			width: 345px;
		}
	}
}

@include media('<small') {
	.column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 1;
		max-width: 320px;
	}

	.double-column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 2;
		max-width: 320px;
	}

	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.top {
		margin: 20px;
	}

	.section-main {
		margin: 10px;

		&-title {
			img {
				max-height: 200px;
				object-fit: contain;
			}
		}
	}

	h1 {
		font-family: 'Helvetica';
		opacity: 0.87;
		color: #002856;
		font-size: 32px;
		letter-spacing: 0.5px;
		line-height: 40px;
	}
}

#bold {
	font-family: 'Helvetica-Bold';
}

#bold-italic {
	font-family: 'Helvetica-Bold';
	font-style: oblique;
}

#bold-underline {
	font-family: 'Helvetica-Bold';
	text-decoration: underline;
	text-align: center;
}
