@import '../include-media.scss';
$breakpoints: (small: 320px, medium: 867px, large: 1024px);

.learn-more-container {
  height: 42px;
  width: 254px;
  border: 1px solid #ed1a3d;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-bottom: 50px;

  p {
    color: rgba(0,0,0,0.62);
    font-family: "Helvetica";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 14px;
    text-align: center;
  }
}

@include media("<medium") {

  .learn-more-container {
    margin: 30px auto 20px auto;
  }

}
